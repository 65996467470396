<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>ตั้งค่า</b></h3>
        <small> ตั้งค่า > <b>ตั้งค่าการคิดราคาระยะทางของลูกค้า</b> </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        
        <div v-if="customerDistancePermission=='create' || customerDistancePermission=='edit'">
        <b-button   variant="primary" size="sm" style="height: 35px" class="mr-1" :disabled="isLoading" @click="updateCountry">
          <feather-icon icon="SaveIcon" v-if="!isLoading"/>
          <b-spinner class="align-middle" v-if="isLoading" small></b-spinner>          
         บันทึก
          
        </b-button>
        </div>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-row>
            <b-col md="3" class="">
              <h3><b>ตั้งค่าการคิดราคาระยะทางของลูกค้า</b></h3>  
                
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2" >
              <b-alert variant="primary" class="p-1" show>
                ต่างจังหวัด
                <b-form inline class="mt-1">
                <b-form-input placeholder="0" style="width:80px" size="sm" v-model:number="distanceOtherSite"/>               
                </b-form>
              </b-alert>
              
            </b-col>
            <b-col cols="2">
              <b-alert variant="primary" class="p-1" show>
                Office
                <b-form inline class="mt-1">
                <b-form-input placeholder="0" style="width:80px" size="sm" v-model:number="distanceOfficeSite"/>              
                </b-form>
              </b-alert>
              
            </b-col>
          </b-row>
          <b-row v-if="isLoading">
            <b-col ><b-spinner class="align-middle" v-if="isLoading"></b-spinner></b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="6">
              <!-- TITLE -->
              <b-row class="mt-2">
                <b-col md="9" class="">
                  <label><b>ที่ตั้ง</b></label>
                </b-col>

                <b-col cols="3" class="">
                  <label><b>ค่าใช้จ่าย/ก.ม.</b></label>
                </b-col>
              </b-row>

              <!-- DATA -->
              <b-row class="mt-1" v-for="country in countryList" :key="country.name">
                <b-col md="9" class="">
                  <b-form-input
                    :placeholder="country.name"
                    size="sm"
                    :value="country.name"
                    readonly
                  />
                </b-col>

                <b-col cols="3" class="">
                  <b-form-input
                    :placeholder="country.costPerKm.toString()"
                    size="sm"
                    v-model.number="country.costPerKm"
                    
                  />
                </b-col>
              </b-row>
            </b-col>

            <!----RIGHT --->
            <b-col cols="6">
              <b-row class="mt-2">
                <b-col v-if="customerDistancePermission=='create' || customerDistancePermission=='edit'">
                  <b-button
                    variant="danger"
                    size="sm"
                    style="height: 35px"
                    class="mr-1"
                    @click="computeAdjustCost('reduce', 0.25)"
                  >
                    - เพิ่ม 0.25
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    style="height: 35px"
                    class="mr-1"
                    @click="computeAdjustCost('add', 0.25)"
                  >
                    + เพิ่ม 0.25
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
  </div>
</template>

<script>
//SERVICES
import CustomerService from "@/services/setting/customer";
import OtherService from "@/services/other"

import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BFormRadioGroup,
  BSpinner,
  BAlert,
  BForm
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BPagination,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BFormRadioGroup,
    BSpinner,
    BAlert,
    BForm
  },
  data() {
    return {
      countryList:[],
      isLoading:false,
      distanceOtherSite:0,
      distanceOfficeSite:0,
    };
  },
  computed:{
    customerDistancePermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST006")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
  },
  mounted() {
    this.getDistanceOtherSite()
    this.getDistanceOfficeSite()
    this.getCountry()
  },
  methods: {
    async getDistanceOtherSite(){
      const res = await OtherService.GetCustomerDistanceOtherSite()
      if(res.data.data.length > 0){
        this.distanceOtherSite = res.data.data[0].floatValue
      }
      
    },
    async getDistanceOfficeSite(){
      const res = await OtherService.GetCustomerDistanceOfficeSite()
      if(res.data.data.length > 0){
        this.distanceOfficeSite = res.data.data[0].floatValue
      }
      
    },
    async editDistanceOtherSite(){
      this.isLoading = true
      let obj = {
        floatValue:this.distanceOtherSite
      }
      const result = await OtherService.EditCustomerDistanceOtherSite(obj)
      if (result.status !== "success") {        
      
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่าระยะทางลูกค้า`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขค่าเดินทางต่างจังหวัด`,
          },
        });
      }
      this.isLoading = false;
      
    },
    async editDistanceOfficeSite(){
      this.isLoading = true
      let obj = {
        floatValue:this.distanceOfficeSite
      }
      const result = await OtherService.EditCustomerDistanceOfficeSite(obj)
      if (result.status !== "success") {        
      
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่าระยะทางลูกค้า`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขค่าเดินทางต่างจังหวัด`,
          },
        });
      }
      this.isLoading = false;
      
    },
    async getCountry() {
      this.isLoading = true
      const result = await CustomerService.GetCountry();      
      this.countryList = result.data.data;  
      this.isLoading = false
    },
    async updateCountry() {
      this.isLoading = true
      await this.editDistanceOtherSite()
      await this.editDistanceOfficeSite()
      const result = await CustomerService.UpdateCustomerCountry(this.countryList);      
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่าระยะทางลูกค้า`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });        
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่าระยะทางลูกค้า`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
      this.isLoading = false;
    },
    computeAdjustCost(symbol, cost) {
      let newZones = this.countryList;

      if (symbol == "add") {
        this.distanceOtherSite+=cost
        this.distanceOfficeSite+=cost
        newZones = this.countryList.map((m) =>{
          const container = m;        
          container.costPerKm = m.costPerKm + cost;
          return container;
          
        });
      
      } else {
        this.distanceOtherSite-=cost
        this.distanceOfficeSite-=cost
        newZones = this.countryList.map((m) => {
          const container = m;      
          if(m.costPerKm!==0){
            container.costPerKm = m.costPerKm - cost;
          }  
          
          return container;
        });
      }

      this.zones = newZones;
    },
  },
};
</script>
<style scoped></style>
