import { BaseUrl } from './ApiUrl'
import AXIOS_SERVICE from '../utils/AxiosUtil'

const OtherService = () => {}

OtherService.GetProvinceList = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/other/province/`, {})
}

OtherService.GetProvinceListByPhaseId = async(phaseId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/other/provinceByPhase/${phaseId}`, {})
}

OtherService.GetPhaseList = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/other/phase/`, {})
}

OtherService.GetCustomerDistanceOtherSite = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/other/general-setting/1`, {})
}
OtherService.GetCustomerDistanceOfficeSite = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/other/general-setting/2`, {})
}

OtherService.EditCustomerDistanceOtherSite = async(payload)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/other/general-setting/1`, payload)
}
OtherService.EditCustomerDistanceOfficeSite = async(payload)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/other/general-setting/2`, payload)
}


 export default OtherService